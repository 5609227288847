define('caroling-companion/mixins/google-pageview', ['exports', 'ember', 'caroling-companion/config/environment'], function (exports, _ember, _carolingCompanionConfigEnvironment) {
  exports['default'] = _ember['default'].Mixin.create({
    beforePageviewToGA: function beforePageviewToGA(ga) {},

    pageviewToGA: _ember['default'].on('didTransition', function (page, title) {
      var page = page ? page : this.get('url');
      var title = title ? title : this.get('url');

      if (_ember['default'].get(_carolingCompanionConfigEnvironment['default'], 'googleAnalytics.webPropertyId') != null) {
        var trackerType = _ember['default'].getWithDefault(_carolingCompanionConfigEnvironment['default'], 'googleAnalytics.tracker', 'analytics.js');

        if (trackerType === 'analytics.js') {
          var globalVariable = _ember['default'].getWithDefault(_carolingCompanionConfigEnvironment['default'], 'googleAnalytics.globalVariable', 'ga');

          this.beforePageviewToGA(window[globalVariable]);

          window[globalVariable]('send', 'pageview', {
            page: page,
            title: title
          });
        } else if (trackerType === 'ga.js') {
          window._gaq.push(['_trackPageview']);
        }
      }
    })

  });
});