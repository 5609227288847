define('caroling-companion/router', ['exports', 'ember', 'caroling-companion/config/environment', 'caroling-companion/mixins/google-pageview'], function (exports, _ember, _carolingCompanionConfigEnvironment, _carolingCompanionMixinsGooglePageview) {

  var Router = _ember['default'].Router.extend(_carolingCompanionMixinsGooglePageview['default'], {
    location: _carolingCompanionConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('about');
    this.route('songs', function () {
      this.route('song', { path: '/:song_id' });
    });
  });

  exports['default'] = Router;
});