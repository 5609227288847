define('caroling-companion/app', ['exports', 'ember', 'caroling-companion/resolver', 'ember-load-initializers', 'caroling-companion/config/environment'], function (exports, _ember, _carolingCompanionResolver, _emberLoadInitializers, _carolingCompanionConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _carolingCompanionConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _carolingCompanionConfigEnvironment['default'].podModulePrefix,
    Resolver: _carolingCompanionResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _carolingCompanionConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});