define('caroling-companion/routes/songs/song', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    // model(params) {
    //   const id = params.song_id;
    //   // const store = this.get('store');
    //   // return store.getSongsById(id);
    // }
    // store: Ember.inject.service()

  });
});